<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn style="margin: 15px ; margin-top: 30px;" color="green" dark v-bind="attrs" v-on="on">
                {{ $t('add students to route') }} + 
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5"> {{ $t('add students to route') }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-autocomplete :items="childrens" item-text="name" item-value="id"
                                v-model="data.registration_record_id" :search-input.sync="studentSearch"
                                :loading="childrensLoading" :label="$t('student name')"
                                :hint="$t('requird')"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-autocomplete :items="routeItems" item-text="name" item-value="id" v-model="data.route_id"
                                :search-input.sync="routeSearch" :loading="driverRoute.routeNameState.loading"
                                :label="$t('route name')" :hint="$t('requird')"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field  v-model="data.lng"
                                
                                :label="$t('Longitude')" :hint="$t('requird')"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field  v-model="data.lat"
                               
                                :label="$t('Latitude')" :hint="$t('requird')"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="data.building_location" :label="$t('location explain')" type="text"
                                :hint="$t('requird')" required></v-text-field>
                        </v-col>
                        <v-col v-if="!driverRoute.addStudentRouteState.loading && dialog" cols="12" sm="6" md="6">
                            <my-map :positionLabel="labelPosition" @position="getPosition($event)"></my-map>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn :color="btnColor" text @click="setData">
                    <span v-if="!driverRoute.addStudentRouteState.loading"> {{ $t('storage') }}</span>
                    <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { driver } from '../../../store/drivers'
import { driverRoute } from '../../../store/driverRoutes'
import notify from '../../../helpers/toast-notifications'
import map from './mapComponent.vue'
import axios from 'axios'
export default {
    components: {
        myMap: map
    },
    data() {
        return {
            driver,
            driverRoute,
            dialog: false,
            data: {
                registration_record_id: null,
                route_id: null,
                lat: null,
                lng: null,
                building_location: null
            },
            studentSearch: null,
            routeSearch: null,
            childrens: [],
            childrensLoading: false
        }
    },
    methods: {
        setData() {
            if (this.btnColor == 'blue') {
                let data = this.data
                data.lng = String(data.lng)
                data.lat = String(data.lat)
                driverRoute.storeStudentRoute(data)
                this.clearData()
                driverRoute.student = []
                driverRoute.route = []
                setTimeout(() => {
                    this.dialog = false
                }, 2000);
            }
            else {
                notify(
                    this.$t('please complete all info'),
                    { timeout: 4000, rtl: true },
                    "error"
                );
            }
        },
        getPosition(e) {
            let result = JSON.parse(e);
            this.data.lat = result.lat
            this.data.lng = result.lng
            localStorage.removeItem('position')
            console.log(this.data)
        },
        clearData() {
            this.data.lng = null
            this.data.lat = null
            this.data.building_location = null
            this.data.route_id = null
            this.data.registration_record_id = null
            this.dialog = false
        }
    },
    computed: {
        studentItems() {
            return driverRoute.student
        },
        routeItems() {
            return driverRoute.route
        },
        btnColor() {
            if (this.data.registration_record_id == null | this.data.route_id == null | this.data.building_location == (null | '') | this.data.lat == null | this.data.lng == null) {
                return 'gray'
            } else {
                return 'blue'
            }
        }, 
        labelPosition() {
            if (this.data.lan == null && this.data.lng == null) {
                console.log(this.data.lan)
                return 'click to add location on the map'
            } else {
                console.log(this.data.lng)
                return 'The site has been determined'
            }
        }
    },
    watch: {
        async studentSearch(val) {
            this.childrensLoading = true;
            if (val != '' && val != null) {
                try {
                    const searchResult = await axios.get("/student/auto-complete", {
                        params: {
                            name: val,
                        },
                    });
                    this.childrens.push(...searchResult.data.data);
                } catch (err) {
                    console.log("err", err);
                } finally {
                    this.childrensLoading = false;
                }
            } else {
                this.childrens = []
            }
        },
        routeSearch(val) {
            if (val != '') {
                driverRoute.getrouteName(val)
            } else {
                driverRoute.route = []
            }
        }
    }
}
</script>