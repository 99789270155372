<template>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-toolbar :dark="$store.state.isDarkMode">
            <template>
              <v-tabs  v-model="Activetab" align-with-title color="#757575">
                <v-tabs-slider></v-tabs-slider>
                <v-col md="3"></v-col>
                <v-col md="3">
                  <v-tab :href="'#' + '1'" @click="Activetab = 1">{{
                    $t("Going Trip")
                  }}</v-tab>
                </v-col>
                <v-col md="3">
                  <v-tab :href="'#' + '2'" @click="Activetab = 2">
                    {{ $t("Return Trip") }}
                  </v-tab>
                </v-col>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-tabs-items :dark="$store.state.isDarkMode" v-model="Activetab">
        <v-tab-item value="1">
          <busRouteGoIndex v-if="Activetab == '1'" />
        </v-tab-item>
        <v-tab-item value="2">
          <busRouteReturnIndex v-if="Activetab == '2'" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </template>
  
  <script>
  import busRouteGoIndex from './busRouteGoIndex.vue'
  import busRouteReturnIndex from "./busRouteReturnIndex.vue";
  export default {
    components: {
        busRouteGoIndex,
        busRouteReturnIndex,
    },
    data() {
      return {
        Activetab: this.$store.state.tabRoute,
      };
    },
    watch: {
    Activetab: function (newval) {
      this.$store.commit("SET_TABROUTE", newval);
    },
  },
  };
  </script>
  
  <style>
  .registration-record__img-wrapper {
    width: 150px;
    height: auto;
    cursor: pointer;
  }
  
  .registration-record__img {
    width: 100%;
  }
  </style>
  