<template>
  <v-container class="px-10">
    <v-row justify="center">
        <v-col md="3">
          <v-text-field  :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <AutoCompleteField :label="$t('Revenue account')" endPoint="/accounting/account/auto-complete"  v-model="filters.account_id" clearable/>
        </v-col>
        <v-col md="3">
          <AutoCompleteField :label="$t('Route Name')" endPoint="/route"  v-model="filters.route_id" clearable/>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode"  :label="$t('hr.employee.note')" 
                            v-model="filters.desc" clearable>
          </v-text-field>
        </v-col>
        <v-col md="9"></v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="fetch(filters)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="12" class="mt-8">
          <v-container id="regular-tables" fluid tag="section">
              <v-simple-table :dark="$store.state.isDarkMode" >
                  <thead class="table-heading" v-if="!$store.state.restrictions.loading">
                      <tr>
                          <th v-for="(name, i) in headers" :key="i" >
                              {{ name.text }}
                          </th>
                      </tr>
                  </thead>
                  <div class="table__spinnerWrapper" v-if="$store.state.restrictions.loading">
                      <app-spinner></app-spinner>
                  </div>
                  <v-fade-transition mode="out-in" :group="true" tag="tbody">
                      <tr v-for="(item, idx) in data" :key="idx"
                          v-show="!$store.state.restrictions.loading">
                          <td>
                              <span>{{ item.id }}</span>
                          </td>
                          <td>
                              {{ item.date }}
                          </td>
                          <td>
                              {{ item.account.name }}
                          </td>
                          <td>
                              {{ item.desc }}
                          </td>
                          <td>
                            <deleteItem path="document" :id="item.id" @record-deleted="fetch"/> 
                            <v-icon color="green" @click="$router.push(`/displayRestriction/${item.id}`)">mdi-eye</v-icon>
                          </td>
                      </tr>
                  </v-fade-transition>
              </v-simple-table>
              <div class=" d-flex justify-end mt-5" v-if="!$store.state.restrictions.loading">
                  <app-pagination :totalPages="$store.state.restrictions.serverTotal" :page="$store.state.restrictions.options.page ?? 1"
                      :perPage="10" @PaginationValue="fetchNewPaginated($event)"></app-pagination>
              </div>
          </v-container>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import spinner from "../../../components/base/spinner.vue";
import pagination from "../../../components/base/pagination.vue";
import deleteItem from "../components/core/delete.vue";
import AutoCompleteField from "../components/core/AutoCompleteField.vue";
export default {
  components:{
    appSpinner: spinner,
    appPagination: pagination,
    deleteItem,
    AutoCompleteField,
  },
  data() {
    return {
      search: '',
      display: false,
      item: null,
      page: 1,
      filters: {
        route_id: null,
        from: null,
        to: null,
        desc: null,
        account_id: null,
      },
    };
  },
  mounted() {
    this.fetch(this.filters)
  },
  watch: {
    "$store.state.restrictions.options": function () {
      this.fetch(this.filters)
    },
  },
  computed: {
    ...mapGetters({
      data: 'restrictions/data',
      headers: 'restrictions/headers'
    })
  },
  methods: {
    ...mapActions({
      fetch: 'restrictions/fetch',
      delete: 'restrictions/delete',
      setPayment: 'restrictions/setPayment',
    }),
    clearFilter(){
      this.filters.account_id = null;
      this.filters.desc = null;
      this.filters.from = null;
      this.filters.to = null;
      this.filters.route_id = null;
      this.fetch(this.filters);
    },
    fetchNewPaginated(value){
      this.$store.state.restrictions.options.page = value;
      this.fetch(this.filters);
    },
  },
  created() {
  }
};
</script>
<style scoped>
#table>>>th,
#table>>>td {
  text-align: center !important;
}
</style>