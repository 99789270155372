<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn style="margin: 15px; margin-top: 30px;" color="green" dark v-bind="attrs" v-on="on">
                {{ $t('generateRestrictions') }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5"> {{ $t('generateRestrictions') }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" >
                            <v-text-field dense  :label="$t('date')" type="date" outlined
                              v-model="data.date">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" >
                            <v-autocomplete dense @update:search-input="handleSearch($event)" outlined :items="cashs"
                                  v-model="data.account_id" item-text="name"
                                  :label="$t('Revenue account')"
                                  item-value="id" ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field dense  :label="$t('explain')" type="text" outlined
                              v-model="data.desc">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue" text @click="setData">
                    <span > {{ $t('save') }}</span>
                    
                </v-btn>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    {{ $t('cancel') }}
                </v-btn>
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {requiredRule} from "@/helpers/validation-rules";
import {mapActions, mapGetters} from "vuex";
import { driver } from '../../../store/drivers'
import { driverRoute } from '../../../store/driverRoutes'
import notify from '../../../helpers/toast-notifications'
import axios from "axios";
import debounce from "../../../helpers/debounce";

export default {
    data() {
        return {
            costCenterItems: [],
      costCenterItemsLoading: false,
            driver,
            driverRoute,
            dialog: false,
            data: {
                date: null,
                account_id: null,
                desc: null
            },
            driverSearch: null

        }
    },
    props: {
        
        tableOptions: {
            type: Object,
            default: () => {
                return {};
            },
        },
        
        
    },
    methods: {
        ...mapActions({
       
        fetchCashs: 'employees/fetchAccounts',
        
      }),
        async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterItemsLoading = false;
        }
      }, 500)();
    },
    async handleSearch(search) {
        if (search) {
          await this.fetchCashs(search)
        }
      },
      async  setData() {
                
      
      let l = {
        document: this.data,
        routes: this.tableOptions.tableData
      }
      

                
                console.log(l);
                const res = await axios.post("route/generate-entry",l)
                if(res.status==201)
                {
                    this.$Notifications(
              "تمت إضافة قيد بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            this.dialog=false
                }
                this.data = {}
                
           
        },


    },
    computed: {
        ...mapGetters({
        
        cashs: 'employees/accounts',
      }),
        items() {
            return driverRoute.driver
        },
        btnColor() {
            if (this.data.name == null | this.data.price == null | this.data.driver_id == null) {
                return 'gray'
            } else {
                return 'blue'
            }
        }
    },
    watch: {
        driverSearch(val) {
            if (val != '') {
                driverRoute.storeDriverName(val)
            } else {
                driverRoute.driver = []
            }

        }
    }
}
</script>