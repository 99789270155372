<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn style="margin: 15px; margin-top: 30px;" color="green" dark v-bind="attrs" v-on="on">
                {{ $t('add driver routes') }} +
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5"> {{ $t('add driver routes') }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="data.name" type="text" :label="$t('name')" :hint="$t('requird')"
                                required></v-text-field>
                        </v-col>
                        <v-col v-if="typeTrip=='GO'" cols="12" sm="6" md="6">
                            <v-text-field  v-model="data.price" type="number" :label="$t('price')" :hint="$t('requird')"
                                required></v-text-field>
                        </v-col>
                        <v-col v-if="typeTrip=='RETURN'" cols="12" sm="6" md="6" class="py-7">
                            <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Modification Permission')" dense v-model="data.trip_editable" 
                            :true-value="1" :false-value="0" color="#757575" required></v-checkbox>
                        </v-col>
                        <v-col v-if="typeTrip=='RETURN'" cols="12">
                            <v-autocomplete :items="items" item-text="name" item-value="id" v-model="data.driver_id"
                                :search-input.sync="driverSearch" :loading="driverRoute.driverNameState.loading"
                                :label="$t('driver name')" :hint="$t('requird')"></v-autocomplete>
                        </v-col>
                        <v-col v-if="typeTrip=='GO'" cols="12" sm="6" md="6">
                            <v-autocomplete :items="items" item-text="name" item-value="id" v-model="data.driver_id"
                                :search-input.sync="driverSearch" :loading="driverRoute.driverNameState.loading"
                                :label="$t('driver name')" :hint="$t('requird')"></v-autocomplete>
                        </v-col>
                        <v-col v-if="typeTrip=='GO'" cols="12" sm="6" md="6" class="py-7">
                            <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Modification Permission')" dense v-model="data.trip_editable" 
                            :true-value="1" :false-value="0" color="#757575" required></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn :color="btnColor" text @click="setData">
                    <span v-if="!driverRoute.driverRouteState.loading"> {{ $t('storage') }}</span>
                    <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { driver } from '../../../store/drivers'
import { driverRoute } from '../../../store/driverRoutes'
import notify from '../../../helpers/toast-notifications'
export default {
    data() {
        return {
            driver,
            driverRoute,
            dialog: false,
            data: {
                name: null,
                price: null,
                driver_id: null,
                trip_editable: 0,
            },
            driverSearch: null
        }
    },
    props: {
        typeTrip: String,
    },
    methods: {
        setData() {
            if (this.btnColor == 'blue') {
                let data = {
                    ...this.data,
                    type: this.typeTrip,
                }
                driverRoute.storeDriverRoute(data)
                this.data = {}
                this.dialog = driverRoute.setRouteData.loading
            }
            else {
                notify(
                    this.$t('please complete all info'),
                    { timeout: 4000, rtl: true },
                    "error"
                );

            }
        },


    },
    computed: {
        items() {
            return driverRoute.driver
        },
        btnColor() {
            if( this.typeTrip == "GO")
            {
                if (this.data.name == null | this.data.price == null | this.data.driver_id == null) {
                return 'gray'
            } else {
                return 'blue'
            }
            } else {
                if (this.data.name == null | this.data.driver_id == null) {
                return 'gray'
            } else {
                return 'blue'
            }
            }
            
        }
    },
    watch: {
        driverSearch(val) {
            if (val != '') {
                driverRoute.storeDriverName(val)
            } else {
                driverRoute.driver = []
            }

        }
    }
}
</script>